import React, { useState, useContext } from "react";
import Container from "./Container";
import InputField from "./InputField";
import { Link, navigate } from "gatsby";
import validateSignupFields from "../util/validations/validateSignupFields";
import axios from "axios";
import { LoaderContext } from "./Loader";
import { ToastContext } from "./Toast";
import { Helmet } from "react-helmet";
import { passwordConstraints, privacyNotice } from "../util/utils";

const Signup = () => {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    company: "",
    email: "",
    password: "",
  });
  const [validationErrors, setErrors] = useState({});
  const loader = useContext(LoaderContext);
  const toast = useContext(ToastContext);

  const handleSignup = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateSignupFields(user);
    if (!isValid) {
      toast.hideToast();
      setErrors(errors);
      return;
    }
    loader.showLoader();
    axios
      .post(`${process.env.BASE_API_URL}/register`, user)
      .then(function (response) {
        loader.hideLoader();
        navigate("/welcome");
      })
      .catch(function (error) {
        loader.hideLoader();
        console.log(error.response.data.message, "error");
        toast.showToast(
          error?.response?.data?.message || "Something has gone wrong with the registration process. Please try again in a few minutes or contact Ivory at (925) 926-1100 and we will correct the problem.",
          "error"
        );
      });
  };

  const handleUserChange = ({ target }) => {
    const clonedUser = { ...user };
    const clonedErrors = { ...validationErrors };
    const { name, value } = target;
    clonedUser[name] = value;
    clonedErrors[name] = "";
    setUser(clonedUser);
    setErrors(clonedErrors);
  };

  return (
    <Container>
      <Helmet>
        <title>Sign up</title>
      </Helmet>
      <div className="signup-container">

        <div className="signup-form">
          <form>

          <InputField
            placeholder="First name"
            type="text"
            value={user.firstName}
            required
            name="firstName"
            id="firstNameInput"
            error={validationErrors?.firstName}
            onChange={handleUserChange}
            autocomplete="given-name"
          />
          <InputField
            placeholder="Last name"
            type="text"
            value={user.lastName}
            required
            name="lastName"
            id="lastNameInput"
            onChange={handleUserChange}
            error={validationErrors?.lastName}
            autocomplete="family-name"
          />
          <InputField
            placeholder="Company name"
            type="text"
            value={user.company}
            required
            name="company"
            id="companyInput"
            onChange={handleUserChange}
            error={validationErrors?.company}
            autocomplete="organization"
          />
          <InputField
            placeholder="Email"
            type="email"
            value={user.email}
            required
            name="email"
            id="emailInput"
            onChange={handleUserChange}
            error={validationErrors?.email}
            autocomplete="email"
          />
          <p className="support-text">
              {passwordConstraints}
          </p>
          <InputField
            placeholder="New password"
            type="password"
            value={user.password}
            required
            name="password"
            id="new-password"
            onChange={handleUserChange}
            error={validationErrors?.password}
            autocomplete="new-password"
          />
          <p className="support-text">
              {privacyNotice}
          </p>
          <div className="signup-button-container">
            <button onClick={handleSignup} className="signup-button">
              Sign up
            </button>
          </div>
          <p className="login-link">
            Already have an account? <Link to="/app/login"> Sign in</Link>
          </p>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default Signup;
