import Validator from "is_js";
import { isEmpty } from "lodash";
function validateLoginFields(data) {
  let errors = {};

  if (Validator.empty(data.email)) {
    errors.email = "Please enter your email.";
  }

  if (!Validator.empty(data.email) && !Validator.email(data.email)) {
    errors.email = "Please enter a valid email.";
  }
  if (Validator.empty(data.password)) {
    errors.password = "Please enter your password.";
  }
  return {
    isValid: isEmpty(errors),
    errors,
  };
}

export default validateLoginFields;
