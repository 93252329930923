import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "@reach/router";
import { Helmet } from "react-helmet";
import { useAuth } from "./auth-context";

const PrivateRoute = ({ component: Component, location, title, ...rest }) => {
  const [user] = useAuth();
  //eslint-disable-next-line eqeqeq
  const isDevelopmentMode = `${process.env.DEV_MODE}`== '9a0fae57d536b7413d93';
  if (!user?.token && location.pathname !== `/app/login` && !isDevelopmentMode) {
    return <Redirect to="/app/login" noThrow state={{ from: location.pathname }} />;
  }
  return (
    <>
      {!!title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <Component {...rest} />
    </>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default PrivateRoute;
