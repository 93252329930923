import Validator from "is_js";
import { isEmpty } from "lodash";
import { passwordRegx, passwordConstraints } from "../utils";

function validateSignupFields(data) {
  let errors = {};

  if (Validator.empty(data.email)) {
    errors.email = "Please enter your email.";
  }

  if (!Validator.empty(data.email) && !Validator.email(data.email)) {
    errors.email = "Please enter a valid email.";
  }

  const naughtyCharactersRegex = /[<>.#;()[\]{}?=]/g;
  if (Validator.empty(data.firstName)) {
    errors.firstName = "Please enter your first name.";
  } else {
    var testfirstName = data.firstName.replace(naughtyCharactersRegex, '');
    if (testfirstName.length === 0){
      errors.firstName = "First name must have at least one alphanumeric character"
    }
  }

  if (Validator.empty(data.lastName)) {
    errors.lastName = "Please enter your last name.";
  } else {
    var testLastName = data.lastName.replace(naughtyCharactersRegex, '');
    if (testLastName.length === 0){
      errors.lastName = "Last name must have at least one alphanumeric character."
    }
  }

  if (Validator.empty(data.company)) {
    errors.company = "Please enter a company name.";
  } else {
    var testCompany = data.company.replace(naughtyCharactersRegex, '');
    if (testCompany.length === 0){
      errors.company = "Company must have at least one alphanumeric character."
    }
  }
  if (Validator.empty(data.password)) {
    errors.password = passwordConstraints;
  }
  if (!Validator.empty(data.password)) {
    if (data.password?.length < 6) {
      errors.password = passwordConstraints;
    } else if (!passwordRegx.test(data.password)) {
      errors.password = passwordConstraints;
    }
  }
  return {
    isValid: isEmpty(errors),
    errors,
  };
}

export default validateSignupFields;
