import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Videos from "../../../layouts/Videos";
import Video from "../../Video";

const VideoGallery = (props) => {
  const data = useStaticQuery(graphql`
  {
    bannerImage: file(relativePath: { eq: "sub_page_heros/video_gallery-2_unfiltered.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    didyouknowmain: file(relativePath: { eq: "video/didyouknowmain.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    didyouknowmain2: file(relativePath: { eq: "video/DIDYOUKNOW-02.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`);
const { bannerImage, didyouknowmain,  didyouknowmain2} = data;
  return (
    <Videos pageContext={{ title: "Did You Know?" }} bannerImage={bannerImage.childImageSharp}>
      <Video
        title="SuperTRUMP Desktop"
        source="vimeo"
        id="637558771?h=f4be977f4f"
        category="SuperTRUMP Desktop|SuperTRUMP - 2021 Desktop Interface"
        featured
        thumbnail={didyouknowmain.childImageSharp}
      />
      <Video
        title="How to Set Up Touch Payments in SuperTRUMP Desktop"
        source="vimeo"
        id="400727112"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Set Up Touch Payments in SuperTRUMP Desktop"
        source="vimeo"
        id="669941511?h=e69f1e51bd"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Set Up Touch Payments in SuperTRUMP Web"
        source="vimeo"
        id="449322745"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Set Up Touch Payments in SuperTRUMP for Salesforce"
        source="vimeo"
        id="463502523"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Use the Chop Function in SuperTRUMP Desktop for Lease Syndication"
        source="vimeo"
        id="400726951"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Import Data From Excel in SuperTRUMP Desktop"
        source="vimeo"
        id="400726908"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Create a Quick Report Group in SuperTRUMP Desktop"
        source="vimeo"
        id="400735520"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Set Up Fee Templates in SuperTRUMP"
        source="vimeo"
        id="571758593"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Upload a Template into SuperTRUMP for Salesforce"
        source="vimeo"
        id="481780869?h=409e51bc0a"
        category="SuperTRUMP Desktop Classic|SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Choose the Right Report in SuperTRUMP Desktop"
        source="vimeo"
        id="411464572"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Setup Custom Index Rates in SuperTRUMP Desktop"
        source="vimeo"
        id="571758014?h=f3528682b3"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Enable or Disable Sections of the Summary Report in SuperTRUMP Desktop"
        source="vimeo"
        id="414781837"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Set Up State Taxes in SuperTRUMP Desktop"
        source="vimeo"
        id="418988142"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Access the Customer Data Window in SuperTRUMP Desktop"
        source="vimeo"
        id="432584956"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Structure a December Transaction to Increase Your Yield in SuperTRUMP Desktop"
        source="vimeo"
        id="423766027"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Structure a December Transaction to Increase Your Yield in SuperTRUMP Web"
        source="vimeo"
        id="445682912"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Structure a December Transaction to Increase Your Yield in SuperTRUMP for Salesforce"
        source="vimeo"
        id="463597710"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add Leverage in SuperTRUMP Desktop to Improve Your Yield and Hold Cash"
        source="vimeo"
        id="428542166"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add a Security Deposit to Enhance the Yield or Lower Customer Payments in SuperTRUMP Desktop"
        source="vimeo"
        id="432937976"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add a Security Deposit to Enhance the Yield or Lower Customer Payments in SuperTRUMP Desktop"
        source="vimeo"
        id="583043205"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add a Security Deposit to Enhance the Yield or Lower Customer Payments in SuperTRUMP Web"
        source="vimeo"
        id="445683150"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add a Security Deposit to Enhance the Yield or Lower Customer Payments in SuperTRUMP for Salesforce"
        source="vimeo"
        id="460357325"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Calculate a Residual Guarantee in SuperTRUMP Desktop"
        source="vimeo"
        id="432585032"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Calculate a Residual Guarantee in SuperTRUMP Desktop"
        source="vimeo"
        id="663056029?h=dc230966b1"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Calculate a Residual Guarantee in SuperTRUMP Web"
        source="vimeo"
        id="445683060"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Calculate a Residual Guarantee in SuperTRUMP for Salesforce"
        source="vimeo"
        id="460232329"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Calculate RVI and Have the Cost Included in Monthly Payments in SuperTRUMP Desktop"
        source="vimeo"
        id="432585075"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to See The Tax Benefits on a Loan or Conditional Sale Transaction in SuperTRUMP Desktop"
        source="vimeo"
        id="432585151"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to See The Tax Benefits on a Loan or Conditional Sale Transaction in SuperTRUMP Desktop"
        source="vimeo"
        id="663056160?h=cefccf196d"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to See The Tax Benefits on a Loan or Conditional Sale Transaction in SuperTRUMP Web"
        source="vimeo"
        id="445682843"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to See The Tax Benefits on a Loan or Conditional Sale Transaction in SuperTRUMP for Salesforce"
        source="vimeo"
        id="460233966"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Set Up and Use the Sinking Fund in SuperTRUMP Desktop"
        source="vimeo"
        id="432585180"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Structure a Tiered Termination Penalty in SuperTRUMP Desktop"
        source="vimeo"
        id="445682717"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Structure a Tiered Termination Penalty in SuperTRUMP Desktop"
        source="vimeo"
        id="663135194?h=c16f04e6bc"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Structure a Tiered Termination Penalty in SuperTRUMP Web"
        source="vimeo"
        id="449321912"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Structure a Tiered Termination Penalty in SuperTRUMP for Salesforce"
        source="vimeo"
        id="463679582"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Upload a Template to SuperTRUMP for Salesforce"
        source="vimeo"
        id="481780869"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add Skip Payments in SuperTRUMP Desktop"
        source="vimeo"
        id="513133096"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add Skip Payments in SuperTRUMP Desktop"
        source="vimeo"
        id="663135267?h=7fd76f59d3"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add Skip Payments in SuperTRUMP Web"
        source="vimeo"
        id="491268062"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add Skip Payments in SuperTRUMP for Salesforce"
        source="vimeo"
        id="487327887"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Change The Payment Structure in SuperTRUMP Desktop"
        source="vimeo"
        id="513109040"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Change The Payment Structure in SuperTRUMP Desktop"
        source="vimeo"
        id="669941393?h=cac0ae7a06"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Change The Payment Structure in SuperTRUMP Web"
        source="vimeo"
        id="492165582"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Change The Payment Structure in SuperTRUMP for Salesforce"
        source="vimeo"
        id="488720725"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Use The Save As Button in SuperTRUMP for Salesforce"
        source="vimeo"
        id="488720988"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Use The Quote Summary Button in SuperTRUMP Desktop"
        source="vimeo"
        id="522090651"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Use The Quote Summary Button in SuperTRUMP Web"
        source="vimeo"
        id="492165517"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add a Fee in SuperTRUMP Desktop"
        source="vimeo"
        id="513131616"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add a Fee in SuperTRUMP Desktop"
        source="vimeo"
        id="669972324?h=20721e772b"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add a Fee in SuperTRUMP Web"
        source="vimeo"
        id="492551419"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add a Fee in SuperTRUMP for Salesforce"
        source="vimeo"
        id="488720842"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add Multiple Assets to a Lease in SuperTRUMP Desktop"
        source="vimeo"
        id="513132429"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add Multiple Assets to a Lease in SuperTRUMP Desktop"
        source="vimeo"
        id="669972095?h=f5ee04653b"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add Multiple Assets to a Lease in SuperTRUMP Web"
        source="vimeo"
        id="491267837"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add Multiple Assets to a Lease in SuperTRUMP for Salesforce"
        source="vimeo"
        id="489566144"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add a Subsidy in SuperTRUMP Desktop"
        source="vimeo"
        id="513130684"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add a Subsidy in SuperTRUMP Desktop"
        source="vimeo"
        id="576930702?h=098b94a53a"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add a Subsidy in SuperTRUMP Web"
        source="vimeo"
        id="492551444"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Add a Subsidy in SuperTRUMP for Salesforce"
        source="vimeo"
        id="489566074"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="The Amortization Schedule Single Loan Report in SuperTRUMP Desktop"
        source="vimeo"
        id="525028972"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="The Amortization Schedule Single Loan Report in SuperTRUMP Desktop"
        source="vimeo"
        id="576867833?h=ddff6e7961"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="The Amortization Schedule Single Loan Report in SuperTRUMP Web"
        source="vimeo"
        id="519250357"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="The Amortization Schedule Single Loan Report in SuperTRUMP for Salesforce"
        source="vimeo"
        id="528393812"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="The Commencement Date and How it Affects the Rent Payment Amount in SuperTRUMP Desktop"
        source="vimeo"
        id="525030585"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="The Commencement Date and How it Affects the Rent Payment Amount in SuperTRUMP Desktop"
        source="vimeo"
        id="576867686?h=b13fb5012c"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="The Commencement Date and How it Affects the Rent Payment Amount in SuperTRUMP Web"
        source="vimeo"
        id="521475737"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="The Commencement Date and How it Affects the Rent Payment Amount in SuperTRUMP for Salesforce"
        source="vimeo"
        id="528393622"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to See Tax Benefits in SuperTRUMP Desktop"
        source="vimeo"
        id="525031706"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to See Tax Benefits in SuperTRUMP Desktop"
        source="vimeo"
        id="576867949?h=d56c16936f"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to See Tax Benefits in SuperTRUMP Web"
        source="vimeo"
        id="521478820"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to See Tax Benefits in SuperTRUMP for Salesforce"
        source="vimeo"
        id="528393015"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Delay a Transaction in SuperTRUMP Desktop"
        source="vimeo"
        id="522872329"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Delay a Transaction in SuperTRUMP Desktop"
        source="vimeo"
        id="663498670?h=4fd5340426"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Delay a Transaction in SuperTRUMP Web"
        source="vimeo"
        id="522090217"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Delay a Transaction in SuperTRUMP for Salesforce"
        source="vimeo"
        id="527399650"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Factor in Delayed Funding in SuperTRUMP Desktop"
        source="vimeo"
        id="527387938"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Factor in Delayed Funding in SuperTRUMP Desktop"
        source="vimeo"
        id="669941742?h=172d24e1ef"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Factor in Delayed Funding in SuperTRUMP Web"
        source="vimeo"
        id="522871305"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Factor in Delayed Funding in SuperTRUMP for Salesforce"
        source="vimeo"
        id="528392520"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Adjust Bonus Depreciation in SuperTRUMP Desktop"
        source="vimeo"
        id="525032690"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Adjust Bonus Depreciation in SuperTRUMP Desktop"
        source="vimeo"
        id="669941685?h=bcde520ff0"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Adjust Bonus Depreciation in SuperTRUMP Web"
        source="vimeo"
        id="522088903"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Adjust Bonus Depreciation in SuperTRUMP for Salesforce"
        source="vimeo"
        id="527398803"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="Different Payment Types for a Stub Period on a Tax Lease in SuperTRUMP Desktop"
        source="vimeo"
        id="525033676"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="Different Payment Types for a Stub Period on a Tax Lease in SuperTRUMP Desktop"
        source="vimeo"
        id="663498570?h=5ad23a1c3e"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="Different Payment Types for a Stub Period on a Tax Lease in SuperTRUMP Web"
        source="vimeo"
        id="522870618"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="Different Payment Types for a Stub Period on a Tax Lease in SuperTRUMP for Salesforce"
        source="vimeo"
        id="527397792"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Create 'What-if' Scenarios in SuperTRUMP Desktop"
        source="vimeo"
        id="522090651?h=5204305f21"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Create 'What-if' Scenarios in in SuperTRUMP Desktop"
        source="vimeo"
        id="708768623?h=7dffb90cb6"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Create 'What-if' Scenarios in SuperTRUMP Web"
        source="vimeo"
        id="492165517?h=aeb2479f35"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Create 'What-if' Scenarios in SuperTRUMP for Salesforce"
        source="vimeo"
        id="488720988?h=b5f29fe351"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Take Advantage of Negative Taxes in SuperTRUMP Desktop"
        source="vimeo"
        id="716144850?h=0c03c398e1"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Take Advantage of Negative Taxes in SuperTRUMP Desktop"
        source="vimeo"
        id="716143970?h=4e571ae235"
        category="SuperTRUMP Desktop"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Take Advantage of Negative Taxes in SuperTRUMP Web"
        source="vimeo"
        id="716142557?h=9f47bdd2bc"
        category="SuperTRUMP Web"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Take Advantage of Negative Taxes in SuperTRUMP for Salesforce"
        source="vimeo"
        id="716142654?h=7b1f74a027"
        category="SuperTRUMP for Salesforce"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="How to Hide the Termination Penalty in SuperTRUMP Desktop"
        source="vimeo"
        id="571758239?h=0d7df9029c"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
      <Video
        title="The Difference Between the Effective and Nominal Yield"
        source="vimeo"
        id="571757785?h=432f49b9c4"
        category="SuperTRUMP Desktop Classic"
        thumbnail={didyouknowmain2.childImageSharp}
      />
    </Videos>
  );
};

export default VideoGallery;
