import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Container from "./Container";
import InputField from "./InputField";
import { Link, navigate } from "gatsby";
import { setLocalStorage } from "../util/auth";
import { LoaderContext } from "./Loader";
import { ToastContext } from "./Toast";
import { Helmet } from "react-helmet";
import validateLoginFields from "../util/validations/validateLoginFields";
import { useAuth } from "./auth-context";
import { useLocation } from "@reach/router";

const Login = () => {
  const location = useLocation();
  const [authState, setAuthState] = useAuth();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [rememberPassword, setRememberPassword] = useState(true);
  const [validationErrors, setErrors] = useState({});
  const loader = useContext(LoaderContext);
  const toast = useContext(ToastContext);
  useEffect(() => {
    if (authState.token) {
      navigate(location?.state?.from || "/app");
    }
  }, [authState.token, location?.state?.from]);
  const handleLogin = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateLoginFields(user);
    if (!isValid) {
      toast.hideToast();
      toast.showToast(
        `There is a problem with your submitted data. Please fix it and try again, or call (925) 926-1100 and press 2 for assistance.`,
        "error"
      );
      setErrors(errors);
      return;
    }
    loader.showLoader();
    axios
      .post(`${process.env.BASE_API_URL}/login`, user)
      .then(function (response) {
        const token = response.data?.data?.token;
        if (typeof token !== "undefined") {
          const userDto = {
            email: user.email,
            ...response.data.data,
            rememberPassword: rememberPassword,
          };
          setLocalStorage(userDto, "authUser", !rememberPassword);
          setAuthState(userDto);
          loader.hideLoader();
          try {
            const fromUrl = location?.state?.from;
            navigate(fromUrl || "/app/resources/downloads");
          } catch (err) {
            navigate("/app/resources/downloads");
          }
        } else {
          loader.hideLoader();
          toast.showToast(response.data?.message || "Something went wrong!", "error");
        }
      })
      .catch(function (error) {
        loader.hideLoader();
        toast.showToast(
          error?.response?.data?.message || "Something went wrong!",
          "error"
        );
      });
  };

  const handleUserChange = ({ target }) => {
    const clonedUser = { ...user };
    const clonedErrors = { ...validationErrors };
    const { name, value } = target;
    clonedUser[name] = value;
    clonedErrors[name] = "";
    setUser(clonedUser);
    setErrors(clonedErrors);
  };

  return (
    <>
      <Helmet>
        <title>Sign in</title>
      </Helmet>
      <Container className="signup-container">
        <form className="signup-form" onSubmit={handleLogin}>
          <InputField
            placeholder="Email"
            type="text"
            value={user.email}
            required
            name="email"
            id="email"
            onChange={handleUserChange}
            error={validationErrors?.email}
            autocomplete="username"
          />
          <InputField
            placeholder="Password"
            type="password"
            value={user.password}
            required
            name="password"
            id="current-password"
            onChange={handleUserChange}
            error={validationErrors?.password}
            autocomplete="current-password"
          />
          <div className="forgot-password-container">
            <div className="input-section">
              <input
                id="remember-password"
                value={rememberPassword}
                checked={rememberPassword}
                type="checkbox"
                onChange={() => setRememberPassword(!rememberPassword)}
              />
              <label htmlFor="remember-password">Keep me signed in</label>
            </div>
          </div>
          <div className="signup-button-container">
            <button type="submit" className="signup-button">
              Sign in
            </button>
          </div>
          <div>
            <Link to="/app/forgot-password">Forgot password?</Link>
          </div>
          <hr/>
          <div>If you registered an account before September 1, 2022, you will need to create a new account.</div>
          <div className="create-button-container">
            <Link to="/app/signup">
              <button className="signup-button">    
              <span>Create account</span>
              </button>
            </Link>
          </div><br/>
        </form>
      </Container>
    </>
  );
};

export default Login;
