export const resourceData = {
  "Case-study-1": {
    urls: ["case-1-lessor-mode-tax-leases-st-salesforce.pdf"],
    assets: [
      {
        id: "case-study-1-salesforce",
        name: "Case 01 - Lessor Mode, Basic Tax Lease",
        extension: "pdf",
        prettySize: "665 KB",
        fileName: "Training-SuperTRUMP-For-Salesforce/SuperTRUMP-for-Salesforce-Case-1-Lessor-Mode-Tax-Leases-2023.pdf",
        publicURL:
          "/case-studies/supertrump-for-salesforce/case-1-lessor-mode-tax-leases-st-salesforce.pdf",
      },
    ],
  },
  "Case-study-2": {
    urls: ["case-2-lender-mode-loans-st-salesforce.pdf"],
    assets: [
      {
        id: "case-study-2-salesforce",
        name: "Case 02 - Lender Mode, Loans",
        extension: "pdf",
        prettySize: "495 KB",
        fileName: "Training-SuperTRUMP-For-Salesforce/SuperTRUMP-for-Salesforce-Case-2-Lender-Mode-Loans-2023.pdf",
        publicURL:
          "/case-studies/supertrump-for-salesforce/case-2-lender-mode-loans-st-salesforce.pdf",
      },
    ],
  },
  "Case-study-3": {
    urls: ["case-3-conditional-sales-agreement-st-salesforce.pdf"],
    assets: [
      {
        id: "case-study-3-salesforce",
        name: "Case 03 - Conditional Sales Agreement",
        extension: "pdf",
        prettySize: "229 KB",
        fileName: "Training-SuperTRUMP-For-Salesforce/SuperTRUMP-for-Salesforce-Case-3-Conditional-Sales-Contract-2023.pdf",
        publicURL:
          "/case-studies/supertrump-for-salesforce/case-3-conditional-sales-agreement-st-salesforce.pdf",
      },
    ],
  },
  "Case-study-4": {
    urls: ["case-4-fees-simple-and-multiple-st-salesforce.pdf"],
    assets: [
      {
        id: "case-study-4-salesforce",
        name: "Case 04 - Fees, Simple & Multiple",
        extension: "pdf",
        prettySize: "419 KB",
        fileName: "Training-SuperTRUMP-For-Salesforce/SuperTRUMP-for-Salesforce-Case-4-Fees-Simple-and-Multiple-2023.pdf",
        publicURL:
          "/case-studies/supertrump-for-salesforce/case-4-fees-simple-and-multiple-st-salesforce.pdf",
      },
    ],
  },
  "Case-study-5": {
    urls: ["case-5-basic-asset-details-and-delayed-funding-st-salesforce.pdf"],
    assets: [
      {
        id: "case-study-5-salesforce",
        name: "Case 05 - Basic Asset Details & Delayed Funding",
        extension: "pdf",
        prettySize: "685 KB",
        fileName: "Training-SuperTRUMP-For-Salesforce/SuperTRUMP-for-Salesforce-Case-5-Basic-Asset-Details-Delayed-Funding-2023.pdf",
        publicURL:
          "/case-studies/supertrump-for-salesforce/case-5-basic-asset-details-and-delayed-funding-st-salesforce.pdf",
      },
    ],
  },
  "Case-study-6": {
    urls: ["case-6-simple-structures-st-salesforce.pdf"],
    assets: [
      {
        id: "case-study-6-salesforce",
        name: "Case 06 - Simple Structures",
        extension: "pdf",
        prettySize: "403 KB",
        fileName: "Training-SuperTRUMP-For-Salesforce/SuperTRUMP-for-Salesforce-Case-6-Simple-Structures-2023.pdf",
        publicURL:
          "/case-studies/supertrump-for-salesforce/case-6-simple-structures-st-salesforce.pdf",
      },
    ],
  },
  "Case-study-7": {
    urls: ["case-7-basic-rent-screens-st-salesforce.pdf"],
    assets: [
      {
        id: "case-study-7-salesforce",
        name: "Case 07 - Basic Rent Screens",
        extension: "pdf",
        prettySize: "680 KB",
        fileName: "Training-SuperTRUMP-For-Salesforce/SuperTRUMP-for-Salesforce-Case-7-Basic-Rent-Screens-2023.pdf",
        publicURL:
          "/case-studies/supertrump-for-salesforce/case-7-basic-rent-screens-st-salesforce.pdf",
      },
    ],
  },
  "Case-study-8": {
    urls: ["case-8-interims-loans-and-leases-st-salesforce.pdf"],
    assets: [
      {
        id: "case-study-8-salesforce",
        name: "Case 08 - Interims, Loans & Leases",
        extension: "pdf",
        prettySize: "553 KB",
        fileName: "Training-SuperTRUMP-For-Salesforce/SuperTRUMP-for-Salesforce-Case-8-Interims-Loans-and-Leases-2022.pdf",
        publicURL:
          "/case-studies/supertrump-for-salesforce/case-8-interims-loans-and-leases-st-salesforce.pdf",
      },
    ],
  },
  "Case-study-9": {
    urls: ["case-9-security-deposit-st-salesforce.pdf"],
    assets: [
      {
        id: "case-study-9-salesforce",
        name: "Case 09 - Security Deposit",
        extension: "pdf",
        prettySize: "445 KB",
        fileName: "Training-SuperTRUMP-For-Salesforce/SuperTRUMP-for-Salesforce-Case-9-Security-Deposit-2023.pdf",
        publicURL:
          "/case-studies/supertrump-for-salesforce/case-9-security-deposit-st-salesforce.pdf",
      },
    ],
  },
  "Case-study-10": {
    urls: ["case-10-TRAC-and-split-TRAC-leases-st-salesforce.pdf"],
    assets: [
      {
        id: "case-study-10-salesforce",
        name: "Case 10 - TRAC & Split TRAC Leases",
        extension: "pdf",
        prettySize: "595 KB",
        fileName: "Training-SuperTRUMP-For-Salesforce/SuperTRUMP-for-Salesforce-Case-10-TRAC-and-Split-TRAC-Leases-2023.pdf",
        publicURL:
          "/case-studies/supertrump-for-salesforce/case-10-TRAC-and-split-TRAC-leases-st-salesforce.pdf",
      },
    ],
  },
  "Case-study-11": {
    urls: ["case-11-simple-subsidy-st-salesforce.pdf"],
    assets: [
      {
        id: "case-study-11-salesforce",
        name: "Case 11 - Simple Subsidy",
        extension: "pdf",
        prettySize: "473 KB",
        fileName: "Training-SuperTRUMP-For-Salesforce/SuperTRUMP-for-Salesforce-Case-11-Simple-Subsidy-2023.pdf",
        publicURL:
          "/case-studies/supertrump-for-salesforce/case-11-simple-subsidy-st-salesforce.pdf",
      },
    ],
  },
  "Case-study-12": {
    urls: ["case-12-multi-asset-st-salesforce.pdf"],
    assets: [
      {
        id: "case-study-12-salesforce",
        name: "Case 12 - Multi-Asset",
        extension: "pdf",
        prettySize: "310 KB",
        fileName: "Training-SuperTRUMP-For-Salesforce/SuperTRUMP-for-Salesforce-Case-12-Multi-Asset-2023.pdf",
        publicURL:
          "/case-studies/supertrump-for-salesforce/case-12-multi-asset-st-salesforce.pdf",
      },
    ],
  },
  "Case-study-13": {
    urls: ["case-13-termination-values-and-ebos-st-salesforce.pdf"],
    assets: [
      {
        id: "case-study-13-salesforce",
        name: "Case 13 - Termination Values & EBOs",
        extension: "pdf",
        prettySize: "563 KB",
        fileName: "Training-SuperTRUMP-For-Salesforce/SuperTRUMP-for-Salesforce-Case-13-Termination-Values-and-EBOs-2023.pdf",
        publicURL:
          "/case-studies/supertrump-for-salesforce/case-13-termination-values-and-ebos-st-salesforce.pdf",
      },
    ],
  },
  "Case-study-14": {
    urls: ["case-14-muni-lease-and-loan-st-salesforce.pdf"],
    assets: [
      {
        id: "case-study-14-salesforce",
        name: "Case 14 - Muni-Lease / Loan (tax-exempt)",
        extension: "pdf",
        prettySize: "357 KB",
        fileName: "Training-SuperTRUMP-For-Salesforce/SuperTRUMP-for-Salesforce-Case-14-Muni-Lease-and-Loan-2023.pdf",
        publicURL:
          "/case-studies/supertrump-for-salesforce/case-14-muni-lease-and-loan-st-salesforce.pdf",
      },
    ],
  },
};
