import Validator from "is_js";
import { isEmpty } from "lodash";

function validateForgetPassword(data) {
  let errors = {};
  if (Validator.empty(data.email)) {
    errors.email = "Email is required";
  }
  if (!Validator.empty(data.email) && !Validator.email(data.email)) {
    errors.email = "Invalid email";
  }
  return {
    isValid: isEmpty(errors),
    errors,
  };
}

export default validateForgetPassword;
