export const resourceData = {
  "Case-study-1": {
    urls: [
      "case-1-lessor-mode-tax-leases.pdf",
      "Case-1A.prm",
      "Case-1B.prm",
      "Case-1C.prm",
    ],
    assets: [
      {
        id: "case-study-1-desktop",
        name: "Case 01 - Lessor Mode, Tax Leases",
        extension: "pdf",
        prettySize: "554 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-1-Lessor-Mode-Tax-Leases.pdf",
        publicURL: "/case-studies/supertrump-desktop/case-1-lessor-mode-tax-leases.pdf",
      },
      {
        id: "parameter-case-study-1a-desktop",
        name: "Parameter File Case 01A",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-1A.prm",
        publicURL: "/parameter-files/case-1/Case-1A.prm",
      },
      {
        id: "parameter-case-study-1b-desktop",
        name: "Parameter File Case 01B",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-1B.prm",
        publicURL: "/parameter-files/case-1/Case-1B.prm",
      },
      {
        id: "parameter-case-study-1c-desktop",
        name: "Parameter File Case 01C",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-1C.prm",
        publicURL: "/parameter-files/case-1/Case-1C.prm",
      },
    ],
  },
  "Case-study-2": {
    urls: ["case-2-lender-mode-loans.pdf", "Case-2A.prm", "Case-2B.prm"],
    assets: [
      {
        id: "case-study-2-desktop",
        name: "Case 02 - Lender Mode, Loans",
        extension: "pdf",
        prettySize: "504 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-2-Lender-Mode-Loans.pdf",
        publicURL: "/case-studies/supertrump-desktop/case-2-lender-mode-loans.pdf",
      },
      {
        id: "parameter-case-study-2a-desktop",
        name: "Parameter File Case 02A",
        extension: "prm",
        prettySize: "18 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-2A.prm",
        publicURL: "/parameter-files/case-2/Case-2A.prm",
      },
      {
        id: "parameter-case-study-2b-desktop",
        name: "Parameter File Case 02B",
        extension: "prm",
        prettySize: "18 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-2B.prm",
        publicURL: "/parameter-files/case-2/Case-2B.prm",
      },
    ],
  },
  "Case-study-3": {
    urls: ["case-3-conditional-sales-contract.pdf", "Case-3A.prm", "Case-3B.prm"],
    assets: [
      {
        id: "case-study-3-desktop",
        name: "Case 03 - Conditional Sales Contract, Template Files, & Quotes",
        extension: "pdf",
        prettySize: "538 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-3-Conditional-Sales-Contract.pdf",
        publicURL:
          "/case-studies/supertrump-desktop/case-3-conditional-sales-contract.pdf",
      },
      {
        id: "parameter-case-study-3a-desktop",
        name: "Parameter File Case 03A",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-3A.prm",
        publicURL: "/parameter-files/case-3/Case-3A.prm",
      },
      {
        id: "parameter-case-study-3b-desktop",
        name: "Parameter File Case 03B",
        extension: "prm",
        prettySize: "10 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-3B.prm",
        publicURL: "/parameter-files/case-3/Case-3B.prm",
      },
    ],
  },
  "Case-study-4": {
    urls: [
      "case-4-fees-simple-and-multiple.pdf",
      "Case-4A.prm",
      "Case-4B.prm",
      "Case-4C.prm",
    ],
    assets: [
      {
        id: "case-study-4-desktop",
        name: "Case 04 - Fees, Simple & Multiple",
        extension: "pdf",
        prettySize: "669 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-4-Fees-Simple-Multiple.pdf",
        publicURL: "/case-studies/supertrump-desktop/case-4-fees-simple-and-multiple.pdf",
      },
      {
        id: "parameter-case-study-4a-desktop",
        name: "Parameter File Case 04A",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-4A.prm",
        publicURL: "/parameter-files/case-4/Case-4A.prm",
      },
      {
        id: "parameter-case-study-4b-desktop",
        name: "Parameter File Case 04B",
        extension: "prm",
        prettySize: "6 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-4B.prm",
        publicURL: "/parameter-files/case-4/Case-4B.prm",
      },
      {
        id: "parameter-case-study-4c-desktop",
        name: "Parameter File Case 04C",
        extension: "prm",
        prettySize: "6 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-4C.prm",
        publicURL: "/parameter-files/case-4/Case-4C.prm",
      },
    ],
  },
  "Case-study-5": {
    urls: [
      "case-5-basic-asset-details-and-delayed-funding.pdf",
      "Case-5A.prm",
      "Case-5B.prm",
    ],
    assets: [
      {
        id: "case-study-5-desktop",
        name: "Case 05 - Basic Asset Details & Delayed Funding",
        extension: "pdf",
        prettySize: "582 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-5-Basic-Asset-Details-and-Delayed-Funding.pdf",
        publicURL:
          "/case-studies/supertrump-desktop/case-5-basic-asset-details-and-delayed-funding.pdf",
      },
      {
        id: "parameter-case-study-5a-desktop",
        name: "Parameter File Case 05A",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-5A.prm",
        publicURL: "/parameter-files/case-5/Case-5A.prm",
      },
      {
        id: "parameter-case-study-5b-desktop",
        name: "Parameter File Case 05B",
        extension: "prm",
        prettySize: "10 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-5B.prm",
        publicURL: "/parameter-files/case-5/Case-5B.prm",
      },
    ],
  },
  "Case-study-6": {
    urls: ["case-6-simple-structures.pdf", "Case-6A.prm", "Case-6B.prm"],
    assets: [
      {
        id: "case-study-6-desktop",
        name: "Case 06 - Simple Structures",
        extension: "pdf",
        prettySize: "385 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-6-Simple-Structures.pdf",
        publicURL: "/case-studies/supertrump-desktop/case-6-simple-structures.pdf",
      },
      {
        id: "parameter-case-study-6a-desktop",
        name: "Parameter File Case 06A",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-6A.prm",
        publicURL: "/parameter-files/case-6/Case-6A.prm",
      },
      {
        id: "parameter-case-study-6b-desktop",
        name: "Parameter File Case 06B",
        extension: "prm",
        prettySize: "10 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-6B.prm",
        publicURL: "/parameter-files/case-6/Case-6B.prm",
      },
    ],
  },
  "Case-study-7": {
    urls: ["case-7-rent-screen-basics.pdf", "Case-7A.prm"],
    assets: [
      {
        id: "case-study-7-desktop",
        name: "Case 07 - Rent Screen Basics",
        extension: "pdf",
        prettySize: "692 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-7-Rent-Screen-Basics.pdf",
        publicURL: "/case-studies/supertrump-desktop/case-7-rent-screen-basics.pdf",
      },
      {
        id: "parameter-case-study-7a-desktop",
        name: "Parameter File Case 07A",
        extension: "prm",
        prettySize: "15 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-7A.prm",
        publicURL: "/parameter-files/case-7/Case-7A.prm",
      },
    ],
  },
  "Case-study-8": {
    urls: ["case-8-interms-loans-and-leases.pdf", "Case-8A.prm", "Case-8B.prm"],
    assets: [
      {
        id: "case-study-8-desktop",
        name: "Case 08 - Interims, Loans & Leases",
        extension: "pdf",
        prettySize: "472 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-8-Interms-Loans-Leases.pdf",
        publicURL: "/case-studies/supertrump-desktop/case-8-interms-loans-and-leases.pdf",
      },
      {
        id: "parameter-case-study-8a-desktop",
        name: "Parameter File Case 08A",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-8A.prm",
        publicURL: "/parameter-files/case-8/Case-8A.prm",
      },
      {
        id: "parameter-case-study-8b-desktop",
        name: "Parameter File Case 08B",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-8B.prm",
        publicURL: "/parameter-files/case-8/Case-8B.prm",
      },
    ],
  },
  "Case-study-9": {
    urls: [
      "case-9-security-deposit-and-asset-re-lease.pdf",
      "Case-9A.prm",
      "Case-9B.prm",
    ],
    assets: [
      {
        id: "case-study-9-desktop",
        name: "Case 09 - Security Deposit",
        extension: "pdf",
        prettySize: "608 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-9-Security-Deposit-and-AssetRe-lease.pdf",
        publicURL:
          "/case-studies/supertrump-desktop/case-9-security-deposit-and-asset-re-lease.pdf",
      },
      {
        id: "parameter-case-study-9a-desktop",
        name: "Parameter File Case 09A",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-9A.prm",
        publicURL: "/parameter-files/case-9/Case-9A.prm",
      },
      {
        id: "parameter-case-study-9b-desktop",
        name: "Parameter File Case 09B",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-9B.prm",
        publicURL: "/parameter-files/case-9/Case-9B.prm",
      },
    ],
  },
  "Case-study-10": {
    urls: ["case-10-TRAC-and-split-TRAC.pdf", "Case-10A.prm", "Case-10B.prm"],
    assets: [
      {
        id: "case-study-10-desktop",
        name: "Case 10 - TRAC & Split TRAC Leases",
        extension: "pdf",
        prettySize: "431 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-10-TRAC-and-Split-TRAC.pdf",
        publicURL: "/case-studies/supertrump-desktop/case-10-TRAC-and-split-TRAC.pdf",
      },
      {
        id: "parameter-case-study-10a-desktop",
        name: "Parameter File Case 10A",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-10A.prm",
        publicURL: "/parameter-files/case-10/Case-10A.prm",
      },
      {
        id: "parameter-case-study-10b-desktop",
        name: "Parameter File Case 10B",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-10B.prm",
        publicURL: "/parameter-files/case-10/Case-10B.prm",
      },
    ],
  },
  "Case-study-11": {
    urls: ["case-11-simple-subsidy.pdf", "Case-11A.prm", "Case-11B.prm"],
    assets: [
      {
        id: "case-study-11-desktop",
        name: "Case 11 - Simple Subsidy",
        extension: "pdf",
        prettySize: "518 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-11-Simple-Subsidy.pdf",
        publicURL: "/case-studies/supertrump-desktop/case-11-simple-subsidy.pdf",
      },
      {
        id: "parameter-case-study-11a-desktop",
        name: "Parameter File Case 11A",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-11A.prm",
        publicURL: "/parameter-files/case-11/Case-11A.prm",
      },
      {
        id: "parameter-case-study-11b-desktop",
        name: "Parameter File Case 11B",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-11B.prm",
        publicURL: "/parameter-files/case-11/Case-11B.prm",
      },
    ],
  },
  "Case-study-12": {
    urls: ["case-12-multi-asset.pd", "Case-12A.prm"],
    assets: [
      {
        id: "case-study-12-desktop",
        name: "Case 12 - Multi-Asset",
        extension: "pdf",
        prettySize: "376 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-12-Multi-Asset.pdf",
        publicURL: "/case-studies/supertrump-desktop/case-12-multi-asset.pdf",
      },
      {
        id: "parameter-case-study-12-desktop",
        name: "Parameter File Case 12",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-12.prm",
        publicURL: "/parameter-files/case-12/Case-12A.prm",
      },
    ],
  },
  "Case-study-13": {
    urls: [
      "case-13-termination-values-and-ebos.pdf",
      "Case-13A.prm",
      "Case-13B.prm",
      "Case-13C.prm",
    ],
    assets: [
      {
        id: "case-study-13-desktop",
        name: "Case 13 - Termination Values & EBOs",
        extension: "pdf",
        prettySize: "643 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-13-Termination-Values-and-EBOs.pdf",
        publicURL:
          "/case-studies/supertrump-desktop/case-13-termination-values-and-ebos.pdf",
      },
      {
        id: "parameter-case-study-13a-desktop",
        name: "Parameter File Case 13A",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-13A.prm",
        publicURL: "/parameter-files/case-13/Case-13A.prm",
      },
      {
        id: "parameter-case-study-13b-desktop",
        name: "Parameter File Case 13B",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-13B.prm",
        publicURL: "/parameter-files/case-13/Case-13B.prm",
      },
      {
        id: "parameter-case-study-13c-desktop",
        name: "Parameter File Case 13C",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-13C.prm",
        publicURL: "/parameter-files/case-13/Case-13C.prm",
      },
    ],
  },
  "Case-study-14": {
    urls: ["case-14-muni-tax-exempt-lease.pdf", "Case-14A.prm", "Case-14B.prm"],
    assets: [
      {
        id: "case-study-14-desktop",
        name: "Case 14 - Muni-Lease / Loan (tax-exempt)",
        extension: "pdf",
        prettySize: "652 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-14-Muni-Tax-Exempt-Lease.pdf",
        publicURL: "/case-studies/supertrump-desktop/case-14-muni-tax-exempt-lease.pdf",
      },
      {
        id: "parameter-case-study-14a-desktop",
        name: "Parameter File Case 14A",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-14A.prm",
        publicURL: "/parameter-files/case-14/Case-14A.prm",
      },
      {
        id: "parameter-case-study-14b-desktop",
        name: "Parameter File Case 14B",
        extension: "prm",
        prettySize: "18 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-14B.prm",
        publicURL: "/parameter-files/case-14/Case-14B.prm",
      },
    ],
  },
  "Case-study-15": {
    urls: ["case-15-lease-syndication.pdf", "Case-15A.prm"],
    assets: [
      {
        id: "case-study-15-desktop",
        name: "Case 15 - Lease Syndication",
        extension: "pdf",
        prettySize: "584 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-15-Lease-Syndication.pdf",
        publicURL: "/case-studies/supertrump-desktop/case-15-lease-syndication.pdf",
      },
      {
        id: "parameter-case-study-15-desktop",
        name: "Parameter File Case 15",
        extension: "prm",
        prettySize: "10 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-15.prm",
        publicURL: "/parameter-files/case-15/Case-15A.prm",
      },
    ],
  },
  "Case-study-16": {
    urls: ["case-16-odds-and-ends.pdf"],
    assets: [
      {
        id: "case-study-16-desktop",
        name: "Case 16 - Odds and Ends",
        extension: "pdf",
        prettySize: "718 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-16-Odds-and-Ends.pdf",
        publicURL:
          "/case-studies/supertrump-desktop/odds-and-ends.pdf",
      },
    ],
  },
  "Case-study-17": {
    urls: ["case-17-direct-finance-lease-treatment.pdf", "Case-17A.prm", "Case-17B.prm"],
    assets: [
      {
        id: "case-study-17-desktop",
        name: "Case 17 - Direct Finance Lease Treatment",
        extension: "pdf",
        prettySize: "390 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-17-Direct-Finance-Lease-Treatment.pdf",
        publicURL:
          "/case-studies/supertrump-desktop/case-17-direct-finance-lease-treatment.pdf",
      },
      {
        id: "parameter-case-study-17a-desktop",
        name: "Parameter File Case 17A",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-17A.prm",
        publicURL: "/parameter-files/case-17/Case-17A.prm",
      },
      {
        id: "parameter-case-study-17b-desktop",
        name: "Parameter File Case 17B",
        extension: "prm",
        prettySize: "5 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-17B.prm",
        publicURL: "/parameter-files/case-17/Case-17B.prm",
      },
    ],
  },
  "Case-study-18": {
    urls: ["case-18-leveraged-leasing.pdf", "Case-18A.prm"],
    assets: [
      {
        id: "case-study-18-desktop",
        name: "Case 18 - Leveraged Leasing & Back Leveraging",
        extension: "pdf",
        prettySize: "499 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-18-Leveraged-Leasing.pdf",
        publicURL: "/case-studies/supertrump-desktop/case-18-leveraged-leasing.pdf",
      },
      {
        id: "parameter-case-study-18-desktop",
        name: "Parameter File Case 18",
        extension: "prm",
        prettySize: "10 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-18.prm",
        publicURL: "/parameter-files/case-18/Case-18A.prm",
      },
    ],
  },
  "Case-study-19": {
    urls: ["case-19-ITC-and-excel-to-st.pdf", "Case-19A.prm", "case-19a-excel.xlsx"],
    assets: [
      {
        id: "case-study-19-desktop",
        name: "Case 19 - ITC & Excel to SuperTRUMP",
        extension: "pdf",
        prettySize: "740 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-19-ITC-and-Excel-to-SuperTRUMP.pdf",
        publicURL: "/case-studies/supertrump-desktop/case-19-ITC-and-excel-to-st.pdf",
      },
      {
        id: "parameter-case-study-19-desktop",
        name: "Parameter File Case 19",
        extension: "prm",
        prettySize: "7 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-19.prm",
        publicURL: "/parameter-files/case-19/Case-19A.prm",
      },
      {
        id: "excel-case-study-19-desktop",
        name: "Excel File Case 19",
        extension: "xlsx",
        prettySize: "17 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-19A-Excel-Sheet.xlsx",
        publicURL: "/case-studies/supertrump-desktop/case-19a-excel.xlsx",
      },
    ],
  },
  "Case-study-20": {
    urls: ["case-20-like-kind-exchange.pdf", "Case-20A.prm"],
    assets: [
      {
        id: "case-study-20-desktop",
        name: "Case 20 - Like-Kind Exchange (LKE)",
        extension: "pdf",
        prettySize: "523 KB",
        fileName: "Training-SuperTRUMP-Desktop-New-Interface/Case-Studies/ST-21-Case-20-Like-Kind-Exchange.pdf",
        publicURL: "/case-studies/supertrump-desktop/case-20-like-kind-exchange.pdf",
      },
      {
        id: "parameter-case-study-20-desktop",
        name: "Parameter File Case 20",
        extension: "prm",
        prettySize: "10 KB",
        fileName: "Training-SuperTRUMP-Desktop-PRMs/Case-20.prm",
        publicURL: "/parameter-files/case-20/Case-20A.prm",
      },
    ],
  },
  // "Case-study-21": {
  //   urls: [
  //     "case-21-word-st-interface.pdf",
  //     "proposal-letter.doc",
  //     "proposal-tv-schedule.doc",
  //   ],
  //   assets: [
  //     {
  //       id: "case-study-21-desktop",
  //       name: "Case 21 - MS Word & SuperTRUMP Interface",
  //       extension: "pdf",
  //       prettySize: "538 KB",
  //       fileName: "case-21-word-st-interface.pdf",
  //       publicURL: "/case-studies/supertrump-desktop/case-21-word-st-interface.pdf",
  //     },
  //     {
  //       id: "proposal-letter-case-study-21-desktop",
  //       name: "Proposal Letter Case 21",
  //       extension: "doc",
  //       prettySize: "173 KB",
  //       fileName: "proposal-letter.doc",
  //       publicURL: "/case-studies/supertrump-desktop/proposal-letter.doc",
  //     },
  //     {
  //       id: "proposal-tv-schedule-case-study-21-desktop",
  //       name: "Proposal TV Schedule Case 21",
  //       extension: "doc",
  //       prettySize: "171 KB",
  //       fileName: "proposal-tv-schedule.doc",
  //       publicURL: "/case-studies/supertrump-desktop/proposal-tv-schedule.doc",
  //     },
  //   ],
  // },
  // "Case-study-22": {
  //   urls: ["case-22-lease-vs-purchase-analysis.pdf", "Case-22A.prm"],
  //   assets: [
  //     {
  //       id: "case-study-22-desktop",
  //       name: "Case 22 - Lease Versus Purchase Analysis",
  //       extension: "pdf",
  //       prettySize: "1.76 MB",
  //       fileName: "case-22-lease-vs-purchase-analysis.pdf",
  //       publicURL:
  //         "/case-studies/supertrump-desktop/case-22-lease-vs-purchase-analysis.pdf",
  //     },
  //     {
  //       id: "parameter-case-study-22-desktop",
  //       name: "Parameter File Case 22",
  //       extension: "prm",
  //       prettySize: "18 KB",
  //       fileName: "Case-22A.prm",
  //       publicURL: "/parameter-files/case-22/Case-22A.prm",
  //     },
  //   ],
  // },
};
