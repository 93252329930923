import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Banner from "../../Banner";
import SubpagesNav from "../../SubpagesNav";
import Section from "../../Section";
import Container from "../../Container";
import Richtext from "../../Richtext";
import Row from "../../Row";
import Column from "../../Column";
import InlineImage from "../../InlineImage";
import Accordion from "../../Accordion";
import Tab from "../../Tab";
import Videos from "../../../layouts/Videos";
import Resource from "../../Resource";
import Video from "../../Video";
import { resourceData } from "./config";

const subpagesNavData = [
  {
    label: "SuperTRUMP Desktop",
    slug:   "/app/training/supertrump-new-desktop-online-training",
    active: false,
  },
  {
    label: "SuperTRUMP Desktop Classic",
    slug: "/app/training/supertrump-desktop-online-training",
    active: true,
  },
  {
    label: "SuperTRUMP for Salesforce",
    slug: "/app/training/supertrump-salesforce-online-training",
    active: false,
  },
  {
    label: "SuperTRUMP Web",
    slug: "/app/training/supertrump-web-online-training",
    active: false,
  },
];

const STDesktopOnlineTraining = (props) => {
  const [resourcesCaseStudy] = useState(resourceData);
  const [isErrorOccur] = useState(false);

  const data = useStaticQuery(graphql`
    {
      bannerImage: file(relativePath: { eq: "banner-photo-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      caseStudyImage: file(relativePath: { eq: "case-study-photo-1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      ivoryStImage: file(relativePath: { eq: "Ivory_ST.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);
  const { bannerImage, caseStudyImage, ivoryStImage } = data;
  return (
    <div>
      <Banner
        title="SuperTRUMP Desktop Classic"
        description="Online Training"
        image={bannerImage.childImageSharp}
      />

      <SubpagesNav data={subpagesNavData} />

      <Section
        preheader="Benefits"
        title="SuperTRUMP Desktop Classic Makes Modeling and Pricing Equipment Leases & Loans Simple"
      >
        <Container size="sm">
          <Richtext align="center">
            Our online training is designed to help you easily and quickly master
            SuperTRUMP Desktop Classic. Whether you are an experienced user or new to equipment
            finance, our online classroom will teach you SuperTRUMP's capabilities from
            the complex to the handy shortcuts using 20 case studies.
          </Richtext>
        </Container>
        <Container>
          <Row gutter="xl" valign="middle">
            <Column size="6">
              <h2 className="align-left">Case Studies</h2>
              <p className="align-left">
                The classroom material is cumulative and our case studies are designed to
                build upon one another. Any given case study may consist of multiple
                problems. Each case study is accompanied by a series of step&#8209;by&#8209;step short
                videos that guide you through each problem. Each case study is available
                as a PDF file to download and print, or you can follow along on your
                monitor.
              </p>
              <p className="align-left">
                We've uploaded unique SuperTRUMP templates{" "}
                <strong className="strong-text-color">
                  (available in the How to Use a Case Study Template section below)
                </strong>{" "}
                for you to work through all of the case studies. These will need to be
                downloaded and placed into your Template/Parameter File folder.
              </p>
            </Column>
            <Column size="6">
              <InlineImage
                className="sal-animate"
                data={caseStudyImage.childImageSharp}
                fluid={true}
                sal="slide-down 600 ease 200"
                maxWidth="90%"
              />
            </Column>
          </Row>
        </Container>
      </Section>

      <Section bgcolor="blue">
        <Container>
          <Row gutter="xxl">
            <Column size="4" className="richtext align-left">
              <h4>How to Use Case a Study Template:</h4>
              <ol>
                <li>Place in a new folder on your desktop: “Case Study Training.”</li>
                <li>
                  Double click on the “Training - Tax Lease for Cases.tem” file to open it
                  in SuperTRUMP.
                </li>
                <li>
                  Do a File – “Save As” to the new Folder on your desktop and name it
                  “Test.” This will cause SuperTRUMP to always save and open new files at
                  this location.
                </li>
              </ol>
            </Column>

            <Column size="4" className="richtext align-left">
              <Richtext>
                Using the case study PDF, follow the step&#8209;by&#8209;step for each problem. You
                can reference the videos if you need extra support.
              </Richtext>
              <ol>
                <li>Read the Objective and Introduction to each case study.</li>
                <li>Read the Overview and Description Highlights of each problem.</li>
                <li>
                  Follow the step&#8209;by&#8209;step rather than filling in numbers from the Quick
                  Screen.
                </li>
              </ol>
            </Column>

            <Column size="4" className="richtext align-left">
              <ol>
                <li>Print the reports suggested.</li>
                <li>Read the Comments and Notes throughout the case studies.</li>
              </ol>
              <p>
                <strong>
                  Please reference the following training templates for the case studies
                  below:
                </strong>
              </p>
              <p>
                <a href="/templates/training-tax-lease-for-cases.tem">
                  Tax Lease for Cases.tem
                </a>
              </p>
              <p>
                <a href="/templates/training-loan-for-cases.tem">Loan for Cases.tem</a>
              </p>
              <p>
                <a href="/templates/training-lease-vs-purchase-for-cases.tem">
                  Lease vs. Purchase for Cases.tem
                </a>
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section title="SuperTRUMP Desktop Classic Case Studies">
        <Container>
          <Accordion toggleFirst={true} id="training-accordion">
            <Tab title="Case Study 01: Lessor Mode, Basic Tax Lease">
              Using a tax lease template, learn how to fill in the SuperTRUMP Quick Screen
              and target for: MISF, IRR, Spread over Index or ROE (either pre-tax or
              after-tax).
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 01A - Lessor Mode, Basic Tax Lease"
                  source="vimeo"
                  id="853127164?h=4d6f39634f"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 01B - Lessor Mode, Basic Tax Lease"
                  source="vimeo"
                  id="853128554?h=b5fc98e2e8"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 01C - Lessor Mode, Basic Tax Lease"
                  source="vimeo"
                  id="853128930?h=db4d2903fe"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 01 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-1"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 02: Lender Mode, Loans">
              Using a loan template, learn how to fill in the SuperTRUMP Quick Screen and
              target for: MISF, IRR, Spread over Index or ROE (either pre-tax or
              after-tax).
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 02A - Lender Mode, Loans"
                  source="vimeo"
                  id="853129052?h=043be7c1ae"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 02B - Lender Mode, Loans"
                  source="vimeo"
                  id="853129203?h=c1e6ce88cf"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 02 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-2"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 03: Conditional Sales Contract, Template Files & Quotes">
              Using a lease template, learn how to price/target a conditional sale, create
              a reusable template and work with quotes.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 03A - Conditional Sales Contract, Template Files, & Quotes"
                  source="vimeo"
                  id="853135579?h=674f99b00f"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 03B - Conditional Sales Contract, Template Files, & Quotes"
                  source="vimeo"
                  id="853129318?h=cdb3074d26"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 03 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-3"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 04: Fees, Simple & Multiple">
              Learn how to work with multiple fees and income items in pricing as well as
              target for a residual value.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 04A - Fees, Simple and Multiple"
                  source="vimeo"
                  id="853129398?h=440bb19ee8"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 04B - Fees, Simple and Multiple"
                  source="vimeo"
                  id="853129506?h=030d3000d0"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 04C - Fees, Simple and Multiple"
                  source="vimeo"
                  id="853129593?h=1b64a01f09"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 04 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-4"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 05: Basic Asset Details & Delayed Funding">
              Learn how to work with the Asset window and change some of the basic
              parameters (MACRS, AMT), and see the effect of delayed funding.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 05A - Basic Asset Details & Delayed Funding"
                  source="vimeo"
                  id="853129717?h=012bf96297"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 05B - Basic Asset Details & Delayed Funding"
                  source="vimeo"
                  id="853129796?h=d90acf7d3a"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 05 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-5"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 06: Simple Structures">
              Learn the effects of different payment structures such as low-high,
              2twopayments in advance and date conventions regarding 30/31.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 06A - Simple Structures"
                  source="vimeo"
                  id="853129921?h=e0d1505c9c"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 06B - Simple Structures"
                  source="vimeo"
                  id="853130029?h=c9de0a99b8"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 06C - Simple Structures"
                  source="vimeo"
                  id="853130129?h=819cd9d340"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 06 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-6"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 07: Basic Rent Screens">
              Learn how to price unusual payment structures such as skips, one&#8209;time
              contract payments and residual realization timing.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 07 Part 1 - Basic Rent Screens"
                  source="vimeo"
                  id="853130243?h=4eeadadaf3"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 07 Part 2 - Basic Rent Screens"
                  source="vimeo"
                  id="853130332?h=95920c0697"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 07 Part 3 - Basic Rent Screens"
                  source="vimeo"
                  id="853130438?h=0193b43ef6"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 07 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-7"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 08: Interims, Loans & Leases">
              Learn how to work with loan and lease interims and understand the effect on
              pricing.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 08A - Interims, Loans & Leases"
                  source="vimeo"
                  id="853130570?h=e62feb100c"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 08B - Interims, Loans & Leases"
                  source="vimeo"
                  id="853130648?h=f058619c7d"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 08 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-8"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 09: Security Deposit">
              Price and lease with a security deposit and understand the effect on yield
              and termination values.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 09A - Security Deposit"
                  source="vimeo"
                  id="853130755?h=430f25b09d"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 09B - Security Deposit"
                  source="vimeo"
                  id="853130880?h=48f936b8a8"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 09 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-9"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 10: TRAC & Split TRAC Leases">
              Learn how to price a TRAC and Split TRAC lease and understand the effects on
              lease classification.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 10A - TRAC & Split TRAC Leases"
                  source="vimeo"
                  id="853130964?h=8f5d43114a"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 10B - TRAC & Split TRAC Leases"
                  source="vimeo"
                  id="853131036?h=7bec984fa1"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 10 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-10"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 11: Simple Subsidy">
              Learn how to price with vendor and captive subsidies both to win the
              business and enhance lessor yield.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 11A - Simple Subsidy"
                  source="vimeo"
                  id="853131186?h=bb7a22d5e4"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 11B - Simple Subsidy"
                  source="vimeo"
                  id="853131329?h=469bd127e6"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 11 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-11"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 12: Multi-Asset">
              Learn to enter more than one asset, each with different residual, delivery
              and funding dates, and different depreciation, all tied to a single lease.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 12 - Multi-Asset"
                  source="vimeo"
                  id="853131451?h=e97c4ee9fc"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 12 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-12"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 13: Termination Values & EBOs">
              Learn to produce a termination table to either protect or enhance your
              economic yield.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 13A - Termination Values & EBOs"
                  source="vimeo"
                  id="853131544?h=6c61c05b1c"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 13B - Termination Values & EBOs"
                  source="vimeo"
                  id="853131638?h=a4c813d541"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 13C - Termination Values & EBOs"
                  source="vimeo"
                  id="853131747?h=be71a7b749"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 13 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-13"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 14: Muni-Lease / Loan (tax-exempt)">
              Learn to price a Muni/Tax-Exempt lease or loan and explore the settings
              necessary for the calculation.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 14A - Muni-Lease / Loan (tax-exempt)"
                  source="vimeo"
                  id="853131862?h=6faf0274c4"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 14B - Muni-Lease / Loan (tax-exempt)"
                  source="vimeo"
                  id="853131984?h=4c9b547757"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 14 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-14"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 15: Lease Syndication">
              Learn to determine the price to pay, as of a funding date, for an existing
              tax lease to earn a desired yield and the simplicity of the chop feature.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 15 - Lease Syndication"
                  source="vimeo"
                  id="853132123?h=8680139af6"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 15 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-15"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 16: Odds and Ends">
              Learn additional SuperTRUMP features to enhance your SuperTRUMP experience.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 16A - Odds and Ends"
                  source="vimeo"
                  id="853132229?h=e5b6cb1e04"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 16B - Odds and Ends"
                  source="vimeo"
                  id="853132322?h=8cffd3a050"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 16C - Odds and Ends"
                  source="vimeo"
                  id="853132408?h=dfcce80e33"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 16 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-16"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 17: Direct Finance Lease Treatment">
              Learn how to price operating and capital leases and how to turn an operating
              lease into a capital lease through RVI insurance.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 17A - Direct Finance Lease Treatment"
                  source="vimeo"
                  id="853132505?h=3b8e9a04d5"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 17B - Direct Finance Lease Treatment"
                  source="vimeo"
                  id="853132608?h=76f12c3736"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 17 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-17"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 18: Leveraged Leasing & Back Leveraging">
              Learn the effect that leverage has on leases, whether it is a leveraged
              lease or uses back leverage.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 18 - Leveraged Leasing & Back Leveraging"
                  source="vimeo"
                  id="853132781?h=deeba717a7"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 18 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-18"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 19: ITC & Excel to SuperTRUMP">
              Learn how to determine the MISF yield from green energy credits and
              integrate Excel numbers.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 19 - ITC & Excel to SuperTRUMP"
                  source="vimeo"
                  id="853132898?h=cb5f5170cb"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 19 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-19"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 20: Like-Kind Exchange (LKE)">
              Learn how to price the value of using the IRS Like-Kind-Exchange benefit in
              a new lease.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 20 - Like-Kind Exchange (LKE)"
                  source="vimeo"
                  id="853133020?h=7bd70eda29"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 20 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-20"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            {/* <Tab title="Case Study 21: MS Word & SuperTRUMP Interface">
              Learn to take data electronically from SuperTRUMP and integrate it into your
              custom MS Word documents. Case study 21 requires Microsoft Word as well as a
              pre-set up .doc file to be altered during this exercise into a .doc file
              that links into SuperTRUMP. Because the version of Microsoft Word used can
              change the process, we recommend that you read over the case study and watch
              the video to see how the linkage works. If you want to use this feature,
              someone from our help desk must work with you to set up your Word templates.
              Save time by having SuperTRUMP print results right into you custom Word
              document.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 21 - MS Word & SuperTRUMP Interface"
                  source="vimeo"
                  id="132870420"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 21 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-21"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 22: Lease Versus Purchase Analysis">
              Learn how to determine if it is better to lease or purchase an asset from
              the perspective of the one using the asset. Providing this analysis to your
              customer puts you in a valuable consultant role.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 22 Part 1 - Lease Versus Purchase Analysis"
                  source="vimeo"
                  id="321856943"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 22 Part 2 - Lease Versus Purchase Analysis"
                  source="vimeo"
                  id="321856888"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 22 Part 3 - Lease Versus Purchase Analysis"
                  source="vimeo"
                  id="321856829"
                  category="SuperTRUMP Desktop"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 22 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-22"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab> */}
          </Accordion>
        </Container>
      </Section>
    </div>
  );
};

export default STDesktopOnlineTraining;
