import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Banner from "../../Banner";
import SubpagesNav from "../../SubpagesNav";
import Section from "../../Section";
import Container from "../../Container";
import Richtext from "../../Richtext";
import Row from "../../Row";
import Column from "../../Column";
import InlineImage from "../../InlineImage";
import Accordion from "../../Accordion";
import Tab from "../../Tab";
import Videos from "../../../layouts/Videos";
import Resource from "../../Resource";
import Video from "../../Video";
import { resourceData } from "./config";

const subpagesNavData = [
  {
    label: "SuperTRUMP Desktop",
    slug:   "/app/training/supertrump-new-desktop-online-training",
    active: false,
  },
  {
    label: "SuperTRUMP Desktop Classic",
    slug: "/app/training/supertrump-desktop-online-training",
    active: false,
  },
  {
    label: "SuperTRUMP for Salesforce",
    slug: "/app/training/supertrump-salesforce-online-training",
    active: false,
  },
  {
    label: "SuperTRUMP Web",
    slug: "/app/training/supertrump-web-online-training",
    active: true,
  },
];

const STWebOnlineTraining = (props) => {
  const [resourcesCaseStudy ] = useState(resourceData);
  const [isErrorOccur ] = useState(false);

  const data = useStaticQuery(graphql`
    {
      caseStudy: file(relativePath: { eq: "case-study-photo-1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      banner: file(relativePath: { eq: "banner-photo-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      ivoryStImage: file(relativePath: { eq: "Ivory_ST.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);
  const { banner, caseStudy, ivoryStImage } = data;

  return (
    <div>
      <Banner
        title="SuperTRUMP Web"
        description="Online Training"
        image={banner.childImageSharp}
      />
      <SubpagesNav data={subpagesNavData} />
      <Section
        preheader="Benefits"
        title="SuperTRUMP Web Makes Modeling and Pricing Equipment Leases & Loans Simple"
      >
        <Container size="sm">
          <Richtext align="center">
            Our online training is designed to help you easily and quickly master
            SuperTRUMP Web. Whether you are an experienced user or new to
            equipment finance, our online classroom will teach you SuperTRUMP's
            capabilities from the complex to the handy shortcuts using 14 case studies.
          </Richtext>
        </Container>
        <Container>
          <Row gutter="xl" valign="middle">
            <Column size="6">
              <Richtext>
                <h2>Case Studies</h2>
                <p>
                  The classroom material is cumulative and our case studies are designed
                  to build upon one another. Any given case study may consist of multiple
                  problems. Each case study is accompanied by a series of step-by-step
                  short videos that guide you through each problem. Each case study is
                  available as a PDF file to download and print, or you can follow along
                  on your monitor.
                </p>
                <p>
                  We've uploaded unique SuperTRUMP templates{" "}
                  <strong>
                    (available in the How to Use a Case Study Template section below)
                  </strong>{" "}
                  for you to work through all of the case studies. These will need to be
                  downloaded and placed into your Template/Parameter File folder.
                </p>
              </Richtext>
            </Column>
            <Column size="6">
              <InlineImage
              className="sal-animate"
                data={caseStudy.childImageSharp}
                fluid={true}
                sal="slide-down 600 ease 200"
                maxWidth="90%"
              />
            </Column>
          </Row>
        </Container>
      </Section>

      <Section bgcolor="blue">
        <Container>
          <Row gutter="xxl">
            <Column size="4">
              <Richtext>
                <h4>How to Use a Case Study Template:</h4>
                <p>
                  To complete the case studies, training templates -{" "}
                  <strong>available at the right of this section</strong> - must be
                  uploaded into SuperTRUMP Web.
                </p>
                <ol>
                  <li>
                    Start the SuperTRUMP Admin tool.
                  </li>
                  <li>
                    Go to the Templates page by clicking on the Templates tab.
                  </li>
                  <li>
                    Click the <strong>New</strong> button and browse where you have saved the Training Templates.
                  </li>
                  <li>
                    Select the templates you want to import and click Open. The templates will be loaded into the SuperTRUMP database and be available to users when they are creating new opportunities and quotes.
                  </li>
                </ol>
              </Richtext>
            </Column>

            <Column size="4">
              <Richtext>
                <p>
                  Using the case study PDF, follow the step-by-step for each problem. You
                  can reference the videos if you need extra support.
                </p>
                <ol>
                  <li>Read the Objective and Introduction to each case study.</li>
                  <li>Read the Overview and Description Highlights of each problem.</li>
                  <li>
                    Follow the Step-by-Step rather than filling in numbers from the Quick
                    Screen.
                  </li>
                  <li>Print the reports suggested.</li>
                  <li>Read the Comments and Notes throughout the case studies.</li>
                </ol>
              </Richtext>
            </Column>

            <Column size="4">
              <Richtext>
                <p>
                  <strong>
                    Please reference the following training templates for the Case Studies
                    below
                  </strong>
                </p>
                <p>
                  <a href="/templates/Salesforce-Web-Training-Lease-for-Cases-2023.tem">
                    Tax Lease for Cases.tem
                  </a>
                </p>
                <p>
                  <a href="/templates/training-loan-for-cases.tem">Loan for Cases.tem</a>
                </p>
                <p>
                  <a href="/templates/training-muni-lease-for-cases.tem">
                    Municipal Lease for Cases.tem
                  </a>
                </p>
                <p>
                  <a href="/templates/training-muni-loan-for-cases.tem">
                    Municipal Loan for Cases.tem
                  </a>
                </p>
              </Richtext>
            </Column>
          </Row>
        </Container>
      </Section>

      <Section title="SuperTRUMP Web Case Studies">
        <Container>
          <Accordion toggleFirst={true} id="training-accordion">
            <Tab title="Case Study 01: Lessor Mode, Basic Tax Lease">
              Price a Tax Lease in SuperTRUMP Web.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 01A - Lessor Mode, Basic Tax Lease"
                  source="vimeo"
                  id="772127473?h=7ec0995702"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 01B - Lessor Mode, Basic Tax Lease"
                  source="vimeo"
                  id="772128033?h=e7c083e8d8"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 01C - Lessor Mode, Basic Tax Lease"
                  source="vimeo"
                  id="772128389?h=e7b46adb52"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 01 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-1"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 02: Lender Mode, Loans">
              Price a basic loan, with and without a balloon payment, given the required
              yield.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 02A - Lender Mode, Loans"
                  source="vimeo"
                  id="772129815?h=10cd22102f"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 02B - Lender Mode, Loans"
                  source="vimeo"
                  id="772130686?h=f5763ba9cd"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 02 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-2"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 03: Conditional Sales Agreement">
              Price a Conditional Sales Agreement.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 03A - Conditional Sales Agreement"
                  source="vimeo"
                  id="772131527?h=75414cd760"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 03B - Conditional Sales Agreement"
                  source="vimeo"
                  id="772131823?h=8a24033ff9"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 03 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-3"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 04: Fees, Simple & Multiple">
              Add or delete fee expense and/or income as part of SuperTRUMP pricing and
              target for residual.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 04A - Fees, Simple and Multiple"
                  source="vimeo"
                  id="772132908?h=0c3ca84d16"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 04B - Fees, Simple and Multiple"
                  source="vimeo"
                  id="772133244?h=448863b29c"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 04 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-4"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 05: Basic Asset Details & Delayed Funding">
              Use the Asset Detail page to change some of the parameters relevant to the
              asset being leased. Learn and understand the breadth and use of the Asset
              Detail page.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 05A - Basic Asset Details & Delayed Funding"
                  source="vimeo"
                  id="772133622?h=c52179653e"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 05B - Basic Asset Details & Delayed Funding"
                  source="vimeo"
                  id="772134105?h=5928683c62"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 05 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-5"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 06: Simple Structures">
              Price a basic tax lease with level payments, low-high, two payments in
              advance and understand the effect on a yield. Problem 6C explores date
              conventions regarding 30/31 days in SuperTRUMP.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 06A - Simple Structures"
                  source="vimeo"
                  id="772134592?h=05382dbe89"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 06B - Simple Structures"
                  source="vimeo"
                  id="772134960?h=ae95a5dd48"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 06C - Simple Structures"
                  source="vimeo"
                  id="772135336?h=440738956a"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 06 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-6"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 07: Basic Rent Screens">
              Understand how to price unusual payment structures like skips, one-time
              contract payments and residual realization timing.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 07 Part 1 - Basic Rent Screens"
                  source="vimeo"
                  id="772136027?h=6c739b12c3"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 07 Part 2 - Basic Rent Screens"
                  source="vimeo"
                  id="772136406?h=9a72bda2a5"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 07 Part 3 - Basic Rent Screens"
                  source="vimeo"
                  id="772136698?h=fb262b16c7"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 07 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-7"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 08: Interims, Loans & Leases">
              Learn how to work with loan and lease interims and understand the effect on
              pricing.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 08A - Interims, Loans & Leases"
                  source="vimeo"
                  id="772137615?h=efa3a31141"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 08B - Interims, Loans & Leases"
                  source="vimeo"
                  id="772137921?h=fc65e85343"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 08 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-8"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 09: Security Deposit">
              Price a basic tax lease to examine the effect on a yield through requiring a
              Security Deposit. Also, notice the effect of a Security Deposit on the
              termination values.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 09 - Security Deposit"
                  source="vimeo"
                  id="772138396?h=6af4649eb0"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 09 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-9"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 10: TRAC & Split TRAC Leases">
              Learn how to price a TRAC and Split TRAC lease and understand the effects on
              lease classification.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 10A - TRAC & Split TRAC Leases"
                  source="vimeo"
                  id="772139018?h=c7c60cdc54"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 10B - TRAC & Split TRAC Leases"
                  source="vimeo"
                  id="772139361?h=e06580100f"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 10 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-10"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 11: Simple Subsidy">
              Price a basic tax lease that has subsidies to examine the effect on the
              yield. Examine subsidies to both enhance the yield of a market rate
              transaction and to determine the subsidy needed on a below-market rate
              transaction to permit the lessor to earn a market rate.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 11A - Simple Subsidy"
                  source="vimeo"
                  id="772139835?h=6d0da231ca"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 11B - Simple Subsidy"
                  source="vimeo"
                  id="772140182?h=94bcec486f"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 11 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-11"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 12: Multi-Asset">
              Learn to enter more than one asset, each with different residual, delivery
              and funding dates, and different depreciation, all tied to a single lease.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 12 - Multi-Asset"
                  source="vimeo"
                  id="772140467?h=4ce28689f8"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 12 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-12"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 13: Termination Values & EBOs">
              Learn to produce a termination table to either protect or enhance your
              economic yield.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 13A - Termination Values & EBOs"
                  source="vimeo"
                  id="772140808?h=e55a17db1b"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 13B - Termination Values & EBOs"
                  source="vimeo"
                  id="772141238?h=9d1851fbf3"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 13C - Termination Values & EBOs"
                  source="vimeo"
                  id="772141606?h=71bbca4952"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 13 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-13"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
            <Tab title="Case Study 14: Muni-Lease / Loan">
              Learn to price a Muni/Tax-Exempt lease or loan and explore the settings
              necessary for the calculation.
              <Videos pageContext={props.pageContext}>
                <Video
                  title="Case 14A - Muni-Lease / Loan"
                  source="vimeo"
                  id="772142271?h=563ad96353"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
                <Video
                  title="Case 14B - Muni-Lease / Loan"
                  source="vimeo"
                  id="772142639?h=187a13f443"
                  category="SuperTRUMP Web"
                  thumbnail={ivoryStImage.childImageSharp}
                />
              </Videos>
              <h3>Case Study 14 Files:</h3>
              <hr />
              <Row>
                {!isErrorOccur &&
                  resourcesCaseStudy["Case-study-14"].assets.map((item, index) => {
                    return (
                      <Column size={3} key={item.publicURL}>
                        <Resource {...item} />
                      </Column>
                    );
                  })}
              </Row>
            </Tab>
          </Accordion>
        </Container>
      </Section>
    </div>
  );
};
export default STWebOnlineTraining;
