export const resourceData = {
  "Case-study-1": {
    urls: ["case-1-lessor-mode-tax-leases-st-web.pdf"],
    assets: [
      {
        id: "case-study-1-web",
        name: "Case 01 - Lessor Mode, Basic Tax Lease",
        extension: "pdf",
        prettySize: "625 KB",
        fileName: "Training-SuperTRUMP-Web/Case-1-Lessor-Mode-Tax-Leases-ST-Web-2022.pdf",
        publicURL:
          "/case-studies/supertrump-web/case-1-lessor-mode-tax-leases-st-web.pdf",
      },
    ],
  },
  "Case-study-2": {
    urls: ["case-2-lender-mode-loans-st-web.pdf"],
    assets: [
      {
        id: "case-study-2-web",
        name: "Case 02 - Lender Mode, Loans",
        extension: "pdf",
        prettySize: "485 KB",
        fileName: "Training-SuperTRUMP-Web/Case-2-Lender-Mode-Loans-ST-Web-2022.pdf",
        publicURL:
          "/case-studies/supertrump-web/case-2-lender-mode-loans-st-web.pdf",
      },
    ],
  },
  "Case-study-3": {
    urls: ["case-3-conditional-sales-agreement-st-web.pdf"],
    assets: [
      {
        id: "case-study-3-web",
        name: "Case 03 - Conditional Sales Agreement",
        extension: "pdf",
        prettySize: "389 KB",
        fileName: "Training-SuperTRUMP-Web/Case-3-Conditional-Sales-Agreement-ST-Web-2022.pdf",
        publicURL:
          "/case-studies/supertrump-web/case-3-conditional-sales-agreement-st-web.pdf",
      },
    ],
  },
  "Case-study-4": {
    urls: ["case-4-fees-simple-and-multiple-web.pdf"],
    assets: [
      {
        id: "case-study-4-web",
        name: "Case 04 - Fees, Simple & Multiple",
        extension: "pdf",
        prettySize: "454 KB",
        fileName: "Training-SuperTRUMP-Web/Case-4-Fees-Simple-and-Multiple-ST-Web-2022.pdf",
        publicURL:
          "/case-studies/supertrump-web/case-4-fees-simple-and-multiple-web.pdf",
      },
    ],
  },
  "Case-study-5": {
    urls: ["case-5-basic-asset-details-and-delayed-funding-st-web.pdf"],
    assets: [
      {
        id: "case-study-5-web",
        name: "Case 05 - Basic Asset Details & Delayed Funding",
        extension: "pdf",
        prettySize: "482 KB",
        fileName: "Training-SuperTRUMP-Web/Case-5-Basic-Asset-Details-and-Delayed-Funding-ST-Web-2022.pdf",
        publicURL:
          "/case-studies/supertrump-web/case-5-basic-asset-details-and-delayed-funding-st-web.pdf",
      },
    ],
  },
  "Case-study-6": {
    urls: ["case-6-simple-structures-st-web.pdf"],
    assets: [
      {
        id: "case-study-6-web",
        name: "Case 06 - Simple Structures",
        extension: "pdf",
        prettySize: "458 KB",
        fileName: "Training-SuperTRUMP-Web/Case-6-Simple-Structures-ST-Web-2022.pdf",
        publicURL:
          "/case-studies/supertrump-web/case-6-simple-structures-st-web.pdf",
      },
    ],
  },
  "Case-study-7": {
    urls: ["case-7-rent-window-basics-st-web.pdf"],
    assets: [
      {
        id: "case-study-7-web",
        name: "Case 07 - Basic Rent Screens",
        extension: "pdf",
        prettySize: "731 KB",
        fileName: "Training-SuperTRUMP-Web/Case-7-Rent-Window-Basics-ST-Web-2022.pdf",
        publicURL:
          "/case-studies/supertrump-web/case-7-rent-window-basics-st-web.pdf",
      },
    ],
  },
  "Case-study-8": {
    urls: ["case-8-interims-loans-and-leases-st-web.pdf"],
    assets: [
      {
        id: "case-study-8-web",
        name: "Case 08 - Interims, Loans & Leases",
        extension: "pdf",
        prettySize: "524 KB",
        fileName: "Training-SuperTRUMP-Web/Case-8-Interims-Loans-Leases-ST-Web_2022.pdf",
        publicURL:
          "/case-studies/supertrump-web/case-8-interims-loans-and-leases-st-web.pdf",
      },
    ],
  },
  "Case-study-9": {
    urls: ["case-9-security-deposit.pdf"],
    assets: [
      {
        id: "case-study-9-web",
        name: "Case 09 - Security Deposit",
        extension: "pdf",
        prettySize: "440 KB",
        fileName: "Training-SuperTRUMP-Web/Case-9-Security-Deposit-and-Asset-Re-Lease-ST-Web-2022.pdf",
        publicURL:
          "/case-studies/supertrump-web/case-9-security-deposit.pdf",
      },
    ],
  },
  "Case-study-10": {
    urls: ["case-10-TRAC-and-split-TRAC-leases-st-web.pdf"],
    assets: [
      {
        id: "case-study-10-web",
        name: "Case 10 - TRAC & Split TRAC Leases",
        extension: "pdf",
        prettySize: "415 KB",
        fileName: "Training-SuperTRUMP-Web/Case-10-TRAC-and-Split-TRAC-Leases-ST-Web_2022.pdf",
        publicURL:
          "/case-studies/supertrump-web/case-10-TRAC-and-split-TRAC-leases-st-web.pdf",
      },
    ],
  },
  "Case-study-11": {
    urls: ["case-11-simple-subsidy-st-web.pdf"],
    assets: [
      {
        id: "case-study-11-web",
        name: "Case 11 - Simple Subsidy",
        extension: "pdf",
        prettySize: "552 KB",
        fileName: "Training-SuperTRUMP-Web/Case-11-Simple-Subsidy-ST-Web-2022.pdf",
        publicURL:
          "/case-studies/supertrump-web/case-11-simple-subsidy-st-web.pdf",
      },
    ],
  },
  "Case-study-12": {
    urls: ["case-12-multi-asset-st-web.pdf"],
    assets: [
      {
        id: "case-study-12-web",
        name: "Case 12 - Multi-Asset",
        extension: "pdf",
        prettySize: "359 KB",
        fileName: "Training-SuperTRUMP-Web/Case-12-Multi-Asset-ST-Web_2022.pdf",
        publicURL:
          "/case-studies/supertrump-web/case-12-multi-asset-st-web.pdf",
      },
    ],
  },
  "Case-study-13": {
    urls: ["case-13-termination-values-and-ebos-st-web.pdf"],
    assets: [
      {
        id: "case-study-13-web",
        name: "Case 13 - Termination Values & EBOs",
        extension: "pdf",
        prettySize: "574 KB",
        fileName: "Training-SuperTRUMP-Web/Case-13-Termination-Values-and-EBOs-ST-Web_2022.pdf",
        publicURL:
          "/case-studies/supertrump-web/case-13-termination-values-and-ebos-st-web.pdf",
      },
    ],
  },
  "Case-study-14": {
    urls: ["case-14-muni-lease-and-loan-st-web.pdf"],
    assets: [
      {
        id: "case-study-14-web",
        name: "Case 14 - Muni-Lease / Loan (tax-exempt)",
        extension: "pdf",
        prettySize: "330 KB",
        fileName: "Training-SuperTRUMP-Web/Case-14-Muni-Lease-and-Loan-ST-Web-2022.pdf",
        publicURL:
          "/case-studies/supertrump-web/case-14-muni-lease-and-loan-st-web.pdf",
      },
    ],
  },
};
