import React, { useEffect } from "react";
import { logout } from "../util/auth";
import { useAuth } from "./auth-context";
import { Redirect } from "@reach/router";

function Logout() {
  const [, setUser] = useAuth();
  useEffect(() => {
    // clear the storage utility
    logout();
    setUser(null);
  }, [setUser]);
  return <Redirect to="/app/login" noThrow />;
}

export default Logout;
