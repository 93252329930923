import React, { useState, useContext } from "react";
import Container from "./Container";
import InputField from "./InputField";
import validateResetFields from "../util/validations/validateResetFields";
import axios from "axios";
import { navigate, Link } from "gatsby";
import { LoaderContext } from "./Loader";
import { ToastContext } from "./Toast";
import { Helmet } from "react-helmet";
import { decodeUri, getParameters } from "../util/utils";
import { passwordConstraints } from "../util/utils";

const ResetPassword = () => {
  const [resetPassword, setResetPassword] = useState({
    confirmPassword: "",
    password: "",
    confirmationCode: "",
  });

  const [validationErrors, setErrors] = useState({});
  const loader = useContext(LoaderContext);
  const toast = useContext(ToastContext);

  const handleResetPassword = () => {
    const { isValid, errors } = validateResetFields(resetPassword);
    const params = getParameters(window.location.search);
    if (isValid) {
      loader.showLoader();
      axios
        .post(`${process.env.BASE_API_URL}/confirm-forgot-password`, {
          email: decodeUri(params.email),
          password: resetPassword.password,
          confirmationCode: resetPassword.confirmationCode,
        })
        .then(function (response) {
          loader.hideLoader();
          toast.showToast("Your password has been successfully reset", "success");
          navigate(`/app/login`);
        })
        .catch(function (error) {
          console.log(error)
          loader.hideLoader();
          toast.showToast(
            error?.response?.data?.message || "There is a problem with your submitted data. Please fix it and try again, or call (925) 926-1100 and press 2 for assistance.",
            "error"
          );
        });
    } else {
      setErrors(errors);
    }
  };

  const handlePasswordChange = ({ target }) => {
    const clonedPasswordSets = { ...resetPassword };
    const clonedErrors = { ...validationErrors };
    const { name, value } = target;
    clonedPasswordSets[name] = value;
    clonedErrors[name] = "";
    setResetPassword(clonedPasswordSets);
    setErrors(clonedErrors);
  };

  return (
    <Container>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <div className="signup-container">
        <div className="signup-form">
        <p className="support-text">
            You have requested a password reset. You should receive an email with a code shortly. Enter the code below. 
          </p>
        <InputField
            placeholder="Confirmation code"
            type="text"
            value={resetPassword.confirmationCode}
            required
            name="confirmationCode"
            onChange={handlePasswordChange}
            error={validationErrors?.confirmationCode}
            autocomplete="one-time-code"
          />
          <p className="support-text">
              {passwordConstraints}
          </p>
          <InputField
            placeholder="New password"
            type="password"
            value={resetPassword.password}
            required
            name="password"
            onChange={handlePasswordChange}
            error={validationErrors?.password}
            autocomplete="new-password"
          />

          <div className="signup-button-container">
            <button onClick={handleResetPassword} className="signup-button">
              Reset password
            </button>
          </div>
          <div>
              <Link to="/account-help">Need other account help?</Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ResetPassword;
