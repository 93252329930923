import React from "react";
import Banner from "../components/Banner";
import Section from "../components/Section";
import Container from "../components/Container";
import Row from "../components/Row";
import Column from "../components/Column";
import Resource from "../components/Resource";
import slugify from "../util/slugify";
import genID from "../util/genID";
import "../util/pardotAnalytics";
import useAnalytics from "../hooks/useAnalytics"

function groupResources({ edges }, children, categories) {
  const full_resources_data = children
    .map((child) => ({ ...child.props }))
    .map((item) => {
      const file_found = edges.find(({ node }) => node.relativePath === item.path);
      return typeof file_found !== "undefined"
        ? { ...file_found.node, ...item, id: genID() }
        : null;
    })
    .filter((file) => file !== null);
  return categories.map((category) => ({
    name: category,
    items: full_resources_data.filter((item) => item.category === category),
  }));
}

function Resources({ data, children, bannerImage }) {
  let categories = children
    .filter(
      (child) =>
        typeof child.props !== "undefined" && typeof child.props.category !== "undefined"
    )
    .map((child) => child.props.category);

  //
  categories = new Set([...categories]);
  categories = [...categories];
  const groupedResources = groupResources(data.resources, children, categories);
  const [currentCategory, setCurrentCategory] = React.useState("");
  const [currentResources, setCurrentResources] = React.useState(groupedResources);
  const [jsOn, setJsOn] = React.useState(false);

  React.useEffect(() => {
    setJsOn(true);
  }, []);

  React.useEffect(() => {
    if (currentCategory.length === 0) {
      setCurrentResources(groupedResources);
    } else {
      setCurrentResources(
        groupedResources.filter((group) => group.name === currentCategory)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategory]);
  
  useAnalytics();

  return (
    <div id="resources">
      <Banner
        title="Download Resources"
        filterOptions={categories}
        filterSet={setCurrentCategory}
        jsOn={jsOn}
        image={bannerImage}
      />
      {currentResources.map((group) => (
        <Section id={slugify(group.name)} key={slugify(group.name)}>
          <Container>
            <h2>{group.name}</h2>
            <hr />
            <Row>
              {group.items.map((item) => (
                <Column key={item.id} size={3}>
                  <Resource {...item} />
                </Column>
              ))}
            </Row>
          </Container>
        </Section>
      ))}
    </div>
  );
}

export default Resources;
