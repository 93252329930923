import React  from "react";
import { Router, Redirect } from "@reach/router";
import Signup from "../components/Signup";
import ForgotPassword from "../components/ForgotPassword";
import ResetPassword from "../components/ResetPassword";
import Login from "../components/Login";
import PrivateRoute from "../components/PrivateRoute";
import STDesktopOnlineTraining from "../components/private/STDesktopOnlineTraining";
import STNewDesktopOnlineTraining from "../components/private/STNewDesktopOnlineTraining";
import STSalesForceOnlineTraining from "../components/private/STSalesForceOnlineTraining";
import STWebOnlineTraining from "../components/private/STWebOnlineTraining";
import Downloads from "../components/private/Downloads";
import VideoGallery from "../components/private/VideoGallery";
import { Spinner } from "../components/Loader";
import { ToastProvider } from "../components/Toast";
import ResendVerification from "../components/ResendVerification";
import { useAuth } from "../components/auth-context";
import Logout from "../components/Logout";

const AppContainer = (props) => {
  return props.children;
};
const IndexRoute = () => {
  const [authState] = useAuth();
  return authState.token ? (
    <Redirect to={`/app/resources/downloads`} noThrow />
  ) : (
    <Redirect to={`/app/login`} noThrow />
  );
};
const App = () => {
  const [{ isLoading }] = useAuth();
  if (isLoading) return <Spinner />;
  return (
    <ToastProvider>
      <Router>
        <AppContainer path="/app">
          <IndexRoute path="/" />
          <Login path="/login" />
          <Signup path="/signup" />
          <ForgotPassword path="/forgot-password" />
          <ResendVerification path="/resend-verification" />
          <ResetPassword path="/reset-password" />
          <PrivateRoute
            path="/training/supertrump-desktop-online-training"
            component={STDesktopOnlineTraining}
            title="SuperTRUMP Desktop"
          />
          <PrivateRoute
            path="/training/supertrump-new-desktop-online-training"
            component={STNewDesktopOnlineTraining}
            title="SuperTRUMP Desktop New GUI"
          />
          <PrivateRoute
            component={STSalesForceOnlineTraining}
            path="/training/supertrump-salesforce-online-training"
            title="SuperTRUMP for Salesforce"
          />
          <PrivateRoute
            path="/training/supertrump-web-online-training"
            component={STWebOnlineTraining}
            title="SuperTRUMP Web"
          />
          <PrivateRoute
            path="/resources/downloads"
            component={Downloads}
            title="Download Resources"
          />
          <PrivateRoute
            path="/resources/video-gallery"
            component={VideoGallery}
            title="Video Gallery"
          />
          <Logout path="/logout" />
        </AppContainer>
      </Router>
    </ToastProvider>
  );
};

export default App;
